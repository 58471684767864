
const BestRatesSvg = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path d="M25.9173 39.1641H21.834M26.0328 36.2229C25.8351 36.0101 25.5875 35.8498 25.3123 35.7566C25.0372 35.6634 24.7432 35.6401 24.4568 35.6889C24.1704 35.7377 23.9007 35.8571 23.672 36.0361C23.4432 36.2152 23.2627 36.4484 23.1466 36.7147C23.0305 36.981 22.9825 37.272 23.007 37.5615C23.0315 37.851 23.1276 38.1298 23.2868 38.3728C23.4459 38.6159 23.6631 38.8154 23.9187 38.9536C24.1742 39.0917 24.4601 39.164 24.7507 39.1641M24.1673 21.6641V32.1641M33.5007 32.1641H35.7033C36.0112 32.1642 36.3137 32.083 36.5803 31.9288C36.8468 31.7746 37.0679 31.5528 37.2213 31.2858C37.3747 31.0188 37.4549 30.716 37.4538 30.4081C37.4528 30.1002 37.3705 29.798 37.2153 29.5321L34.6673 25.1641M28.834 32.1757V32.1641" stroke="#252525" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

            <path d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35235 4.55426 13.0377 2.28363 18.5195C0.0129988 24.0013 -0.581102 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4236C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4458 51.6477 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C59.991 22.0463 56.8274 14.4209 51.2033 8.79674C45.5791 3.17259 37.9537 0.00899894 30 0ZM30 58C24.4621 58 19.0486 56.3578 14.444 53.2812C9.83947 50.2045 6.25064 45.8315 4.13139 40.7151C2.01213 35.5988 1.45764 29.9689 2.53803 24.5375C3.61841 19.106 6.28515 14.1169 10.201 10.201C14.1169 6.28514 19.106 3.6184 24.5375 2.53801C29.969 1.45763 35.5988 2.01212 40.7152 4.13137C45.8315 6.25063 50.2045 9.83946 53.2812 14.444C56.3578 19.0486 58 24.4621 58 30C57.9915 37.4235 55.0388 44.5405 49.7896 49.7896C44.5405 55.0388 37.4235 57.9915 30 58Z" fill="black" />

            <path d="M47.2283 16.3472C47.5043 15.8741 47.6512 15.3369 47.6543 14.7892C47.6543 14.1619 47.4683 13.5486 47.1198 13.027C46.7713 12.5054 46.276 12.0988 45.6964 11.8587C45.1169 11.6186 44.4791 11.5558 43.8639 11.6781C43.2486 11.8004 42.6834 12.1024 42.2397 12.5459C41.7961 12.9894 41.4939 13.5545 41.3714 14.1697C41.2489 14.785 41.3116 15.4227 41.5515 16.0024C41.7914 16.582 42.1978 17.0775 42.7193 17.4261C43.2408 17.7748 43.854 17.961 44.4813 17.9612C44.9165 17.9611 45.347 17.8705 45.7453 17.6952C48.5596 21.2983 50.0541 25.7571 49.9796 30.3285C49.905 34.8998 48.2661 39.3076 45.3359 42.8171C42.4056 46.3266 38.3611 48.7258 33.8765 49.6149C29.3918 50.504 24.7378 49.8293 20.6903 47.7032C20.5738 47.6371 20.4452 47.5952 20.3121 47.5798C20.1791 47.5644 20.0443 47.5759 19.9158 47.6136C19.7872 47.6513 19.6676 47.7145 19.5639 47.7993C19.4603 47.8841 19.3747 47.9889 19.3123 48.1075C19.25 48.226 19.212 48.3558 19.2008 48.4893C19.1896 48.6228 19.2052 48.7572 19.2469 48.8844C19.2886 49.0117 19.3555 49.1293 19.4435 49.2303C19.5315 49.3313 19.6389 49.4135 19.7593 49.4722C24.2229 51.8259 29.3603 52.5725 34.3092 51.5868C39.2581 50.6011 43.7173 47.943 46.9384 44.0587C50.1595 40.1743 51.9464 35.3002 51.9992 30.2544C52.052 25.2085 50.3675 20.2981 47.2283 16.3472ZM43.3103 14.7892C43.3103 14.5573 43.3791 14.3307 43.5079 14.138C43.6367 13.9452 43.8198 13.795 44.034 13.7063C44.2483 13.6176 44.484 13.5945 44.7113 13.6398C44.9387 13.6851 45.1475 13.7968 45.3114 13.9608C45.4753 14.1248 45.5868 14.3337 45.6319 14.5612C45.677 14.7886 45.6537 15.0243 45.5648 15.2384C45.476 15.4525 45.3256 15.6355 45.1327 15.7642C44.9399 15.8928 44.7132 15.9614 44.4813 15.9612C44.1707 15.9609 43.8728 15.8373 43.6532 15.6176C43.4337 15.3978 43.3103 15.0998 43.3103 14.7892Z" fill="black" />

            <path d="M14.7927 42.0389C14.5598 42.0402 14.3277 42.0674 14.1007 42.1199C11.4295 38.6469 9.98705 34.3853 9.99974 30.0039C10.0058 24.7014 12.1149 19.6179 15.8643 15.8684C19.6137 12.119 24.6973 10.0099 29.9997 10.0039C32.9245 9.99615 35.8147 10.6361 38.4627 11.8779C38.703 11.9903 38.9781 12.0027 39.2276 11.9123C39.477 11.8219 39.6803 11.6361 39.7927 11.3959C39.9052 11.1556 39.9176 10.8805 39.8272 10.631C39.7368 10.3816 39.551 10.1783 39.3107 10.0659C34.8696 7.9871 29.8611 7.45246 25.0812 8.54689C20.3014 9.64133 16.0245 12.302 12.9303 16.1061C9.83617 19.9102 8.10234 24.6394 8.00439 29.542C7.90644 34.4446 9.45001 39.2392 12.3897 43.1639C11.8965 43.7321 11.6236 44.4584 11.6207 45.2109C11.6205 45.8385 11.8065 46.4521 12.1551 46.9741C12.5037 47.496 12.9992 47.9029 13.579 48.1431C14.1589 48.3834 14.7969 48.4463 15.4125 48.3239C16.0281 48.2015 16.5936 47.8993 17.0374 47.4555C17.4812 47.0117 17.7834 46.4462 17.9058 45.8306C18.0282 45.215 17.9653 44.577 17.725 43.9971C17.4847 43.4173 17.0779 42.9218 16.5559 42.5732C16.034 42.2246 15.4204 42.0387 14.7927 42.0389ZM14.7927 46.3829C14.561 46.3827 14.3345 46.3137 14.1419 46.1848C13.9493 46.0559 13.7992 45.8728 13.7107 45.6587C13.6221 45.4445 13.5991 45.2089 13.6444 44.9816C13.6897 44.7543 13.8014 44.5456 13.9654 44.3818C14.1293 44.218 14.3381 44.1064 14.5655 44.0613C14.7928 44.0162 15.0284 44.0394 15.2425 44.1282C15.4566 44.2169 15.6396 44.3671 15.7683 44.5598C15.897 44.7525 15.9657 44.9791 15.9657 45.2109C15.9657 45.3648 15.9354 45.5173 15.8764 45.6596C15.8175 45.8018 15.7311 45.9311 15.6221 46.0399C15.5132 46.1488 15.3839 46.2351 15.2416 46.2939C15.0992 46.3528 14.9467 46.383 14.7927 46.3829Z" fill="black" />

        </svg>

    )
}

export default BestRatesSvg