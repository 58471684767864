import BestRatesSvg from '~/components/assets/svg/BestRatesSvg';
import arabCity from '~/components/assets/arab.png';
import VerifiedTrucksSvg from '~/components/assets/svg/VerifiedTrucksSvg';
import OnTimeDeliverSvg from '~/components/assets/svg/OnTimeDeliverSvg';
import CustomerServiceSvg from '~/components/assets/svg/CustomerServiceSvg';
import PaperMapSvg from '~/components/assets/svg/PaperMapSvg';
import CustomerSurveySvg from '~/components/assets/svg/CustomerSurveySvg';
import { MetaFunction } from '@remix-run/node';
import util from '~/utils/util';



const cardData = [
  {
    icon: <BestRatesSvg />,
    title: "Best Truck Rates",
    description: "Transparent and Best Pricing in UAE",
  },
  {
    icon: <VerifiedTrucksSvg />,
    title: "Verified Trucks",
    description: "Large network of verified trucks across UAE",
  },
  {
    icon: <OnTimeDeliverSvg />,
    title: "On Time Delivery",
    description: "Real time truck tracking to deliver your goods on time",
  },
  {
    icon: <CustomerServiceSvg />,
    title: "Customer Service",
    description: "We provide 24/7 customer support",
  },
];

export const meta: MetaFunction = () => {
  return [
    ...util.baseMeta,
    { title: "Land Transportation Services & Logistics Company in UAE" },
    {
      property: "og:title",
      content: "Land Transport, Logistics Company in UAE; Road Transportation Service in UAE",
    },
    {
      name: "description",
      content: "Need land transportation services in the UAE? FR8, as one of the top logistics companies in UAE, provides truck rental services to deliver your goods on time.",
    },
  ];
};

const Home = () => {

  const onClickGetStarted = () => {
    window.open('https://web.whatsapp.com/send?phone=918925510874&text=','_blank')
  }

  return (
    <>

      {/* Section 1 */}
      <section className='relative h-[calc(70vh-70px)] md:h-[calc(95vh-70px)] pb-10 '>
        <div className='max-w-6xl  relative z-10 mx-auto px-4 pt-[10vh]'>
          <div className="text-center">
            <h1 className="heading"><span className="text-secondary">UAE</span> Truck Transportation</h1>
            <h1 className="heading">and Logistics Services</h1>
            <div className="text-sm md:text-lg mt-10">
              <p className='tracking-wider'>  Hire trucks from our network of verified truck owners with 100% delivery guaranteed.</p>
            </div>
            <button type="button" onClick={onClickGetStarted} className="text-white bg-secondary font-medium rounded-lg focus:ring-2  text-sm mt-10 px-5 py-2.5 me-2 mb-2 ">Get Started</button>
          </div>
        </div>
        <img src={arabCity} className='absolute bottom-10' style={{ zIndex: -1 }} />
      </section> 


      {/* Section 2 */}
      <section className="max-w-screen p-6 py-16  bg-tertiary flex justify-center">

        <div className="flex flex-col items-center justify-center ">
          <div className="text-center">
            <h3 className="text-2xl md:text-3xl heading ">Why FR8 is the UAE's perfect logistics partner?</h3>
            <p className="m-8 mb-10 text-base md:text-lg ">FR8 gives 100% delivery guarantee for all trucks booked with us.</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">

            {cardData.map((card, index) => (
              <div key={index} className="flex flex-col text-sm card py-10 px-8">
                {card.icon}
                <h4 className="text-[1.6rem] font-extrabold heading">{card.title}</h4>
                <p className="tracking-wider">{card.description}</p>
              </div>
            ))}
          </div>

        </div>
      </section>

      {/* Section 3 */}
      <section className="mt-12">

        <h3 className="text-3xl md:text-[2.7rem] heading text-center mb-8">A Reliable Transport Company in UAE</h3>

        <div className='max-w-6xl mx-auto pb-4 md:py-10'>
          <div className="grid grid-cols-1 lg:grid-cols-2 ">

            <div className="order-2 lg:order-1 text-sm px-6 md:px-4">
              <p className="text-xl tracking-wider md:text-3xl font-semibold heading  mb-4">Effortlessly Transport Goods Across the UAE</p>
              <p className="mb-6 tracking-wider leading-relaxed">OpenFR8, your trusted logistics company, simplifies the process of moving your goods throughout the Emirates. We are a leading transport company ensuring a seamless and efficient logistics experience. No matter the size or distance of your shipment, OpenFR8 provides you with a reliable logistics service for all your transportation needs.</p>
              <ul className="list-disc leading-relaxed tracking-wider list-inside mb-2 pl-6">
                <li><span className='font-semibold'>Wide Network of Trucks:</span> We have a vast network of verified truck owners throughout the UAE, ensuring you have access to a diverse fleet of trucks and experienced drivers to meet your specific requirements.</li>
                <li><span className='font-semibold'>Competitive Rates:</span> Compare truck rates to find the most cost-effective solution for your shipment. Our transparent pricing structure eliminates hidden fees, so you know exactly what you’re paying upfront.</li>
              </ul>

            </div>

            <div className="order-1 lg:order-2 flex justify-center items-center">
              <PaperMapSvg />
            </div>
          </div>
        </div>

        <div className='bg-tertiary py-6 md:py-10'>
          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2">

              <div className="order-1 lg:order-1 flex justify-center items-center">
                <CustomerSurveySvg />
              </div>

              <div className="order-2 lg:order-2 text-sm px-6 md:px-4">
                <h4 className="text-xl md:text-3xl font-semibold heading mb-4">Online Truck Booking System</h4>
                <p className="mb-6 tracking-wider leading-relaxed">Our user-friendly online truck booking system empowers you to take control. Get instant quotes, compare rates, and book the perfect truck for your goods – all within a few clicks. Whether you need a small truck for intra-city or a heavy-duty truck for inter city hauls, our diverse fleet caters to any shipment size.</p>
                <ul className="list-disc list-inside  pl-6">
                  <li className='tracking-wider leading-relaxed'><span className='font-semibold'>24/7 Access:</span> Manage your logistics needs anytime, anywhere with our user-friendly online platform. Our modern truck booking system helps you book trucks, and track your shipments around the clock for ultimate convenience.</li>
                  <li className='tracking-wider leading-relaxed'><span className='font-semibold'>Real-time Tracking:</span> Enjoy peace of mind with real-time shipment tracking. Monitor the location of your cargo throughout its journey and receive updates every step of the way.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='max-w-6xl mx-auto py-4 md:py-10'>
          <div className="grid grid-cols-1 lg:grid-cols-2 ">
            <div className="order-2 lg:order-1 text-sm  px-6 md:px-4">
              <h4 className="text-xl md:text-3xl font-semibold heading  mb-4">Focus on Your Business, We Handle the Logistics</h4>
              <p className="mb-6 tracking-wider leading-relaxed">As a logistics service provider, we understand that focusing on your core business is crucial. Let us handle the logistics! We take full responsibility for your goods, and ensure a smooth delivery process.  Experience the difference with OpenFR8 – your trusted logistics company in the UAE.
              </p>
              <ul className="list-disc list-inside mb-2 pl-6">
                <li className='tracking-wider leading-relaxed'><span className='font-semibold'>Exceptional Customer Service:</span> Our dedicated team is here to provide exceptional customer service throughout your entire logistics journey.</li>
                <li className='tracking-wider leading-relaxed'><span className='font-semibold'>Complete Guarantee:</span> We offer a delivery guarantee on your goods, giving you peace of mind throughout the transportation process.</li>
              </ul>
            </div>

            <div className="order-1 lg:order-2 flex justify-center items-center">
              <PaperMapSvg />
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default Home;