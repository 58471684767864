
const CustomerServiceSvg = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.3574 2C26.424 2 20.6238 3.75947 15.6903 7.05591C10.7569 10.3524 6.91168 15.0377 4.64105 20.5195C2.37042 26.0013 1.77632 32.0333 2.93388 37.8527C4.09143 43.6721 6.94866 49.0176 11.1442 53.2132C15.3398 57.4088 20.6853 60.266 26.5047 61.4236C32.3242 62.5811 38.3562 61.987 43.8379 59.7164C49.3197 57.4458 54.0051 53.6006 57.3015 48.6671C60.598 43.7336 62.3574 37.9334 62.3574 32C62.3484 24.0463 59.1848 16.4209 53.5607 10.7967C47.9366 5.17259 40.3112 2.009 32.3574 2ZM32.3574 60C26.8196 60 21.406 58.3578 16.8015 55.2812C12.1969 52.2045 8.60806 47.8315 6.48881 42.7151C4.36955 37.5988 3.81506 31.9689 4.89545 26.5375C5.97583 21.106 8.64257 16.1169 12.5584 12.201C16.4743 8.28514 21.4634 5.6184 26.8949 4.53801C32.3264 3.45763 37.9562 4.01212 43.0726 6.13137C48.1889 8.25063 52.5619 11.8395 55.6386 16.444C58.7153 21.0486 60.3574 26.4621 60.3574 32C60.349 39.4235 57.3963 46.5405 52.1471 51.7896C46.8979 57.0388 39.7809 59.9915 32.3574 60Z" fill="#272727" />
            <path d="M47.526 24.4017H46.0362C45.5778 21.6094 44.3172 19.0499 42.369 16.9557C39.7332 14.3961 36.4098 13 32.8572 13H31.1382C24.4914 13 19.1052 17.8864 18.0738 24.4017H16.584C15.3234 24.4017 14.1774 24.867 13.3752 25.9141C12.4584 26.7285 12 28.0083 12 29.2881V34.6399C12 37.3158 14.0628 39.5263 16.584 39.5263H19.1052C19.449 39.5263 19.6782 39.41 19.9074 39.1773C20.1366 38.9446 20.2512 38.7119 20.2512 38.3629V35.8033V26.6122C20.2512 20.446 25.179 15.3269 31.2528 15.3269H32.9718C35.8368 15.3269 38.7018 16.4903 40.7646 18.5845C42.8274 20.6787 43.9734 23.4709 43.9734 26.4958V38.3629C43.9734 43.3657 40.65 47.6704 36.066 49.1828C35.3784 47.6704 33.774 46.6233 31.9404 46.7396C30.7944 46.7396 29.763 47.3213 29.0754 48.1357C28.3878 48.9501 27.9294 49.9972 28.044 51.0443C28.1586 53.2549 29.9922 55 32.1696 55C32.2842 55 32.2842 55 32.3988 55C33.5448 55 34.5762 54.4183 35.2638 53.6039C35.7222 53.0222 36.066 52.3241 36.1806 51.626C41.5668 50.1136 45.5778 45.3435 46.0362 39.5263H47.4114C48.672 39.5263 49.818 39.0609 50.6202 38.0139C51.4224 37.0831 51.9954 35.9197 51.9954 34.6399V29.2881C52.11 26.6122 50.0472 24.4017 47.526 24.4017ZM16.584 37.1994C15.3234 37.1994 14.292 36.036 14.292 34.6399V29.2881C14.292 28.59 14.5212 27.892 14.9796 27.4266C15.438 26.9612 16.011 26.7285 16.584 26.7285H17.9592V37.1994H16.584ZM33.5448 51.9751C33.201 52.3241 32.7426 52.5568 32.1696 52.5568C31.2528 52.5568 30.2214 51.8587 30.2214 50.8116C30.2214 50.3463 30.336 49.8809 30.6798 49.5319C31.0236 49.1828 31.482 48.9501 32.055 48.9501C33.0864 48.9501 34.0032 49.6482 34.0032 50.6953C34.0032 51.1607 33.8886 51.626 33.5448 51.9751ZM49.818 34.6399C49.818 35.338 49.5888 36.036 49.1304 36.5014C48.672 36.9668 48.099 37.1994 47.526 37.1994H46.1508V26.7285H47.526C48.7866 26.7285 49.818 27.892 49.818 29.2881V34.6399Z" fill="#272727" />
        </svg>

    )
}

export default CustomerServiceSvg