
const VerifiedTrucksSvg = () => {
    return (
        <svg width="70" height="70" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1688 6.99755L29.1957 6.98472L29.2216 6.96979C29.3492 6.89612 29.5776 6.83086 29.862 6.82821C30.142 6.8256 30.4061 6.88464 30.5975 6.98478L30.6102 6.99141L30.6231 6.99755L49.1228 15.7968C49.1229 15.7968 49.1229 15.7968 49.123 15.7968C49.5372 15.9948 49.8533 16.3526 49.9986 16.7883L49.9988 16.7888C50.7135 18.9254 51.1239 21.2189 51.1239 23.6098C51.1239 35.0158 45.9619 42.621 40.7889 47.3924C38.1984 49.7819 35.6061 51.4584 33.6623 52.5368C32.6911 53.0756 31.8838 53.464 31.3223 53.7166C31.0416 53.8428 30.8226 53.935 30.6754 53.995C30.6018 54.025 30.5462 54.047 30.5099 54.0611L30.4701 54.0764L30.463 54.0791C30.0962 54.2095 29.6956 54.2095 29.3288 54.0791L29.3217 54.0764L29.2819 54.0611C29.2456 54.047 29.19 54.025 29.1164 53.995C28.9693 53.935 28.7502 53.8428 28.4695 53.7166C27.908 53.464 27.1007 53.0756 26.1295 52.5368C24.1858 51.4584 21.5934 49.7819 19.0029 47.3924C13.8299 42.621 8.66797 35.0158 8.66797 23.6098C8.66797 21.2189 9.07836 18.9254 9.793 16.7888L9.79319 16.7882C9.93843 16.3528 10.2543 15.995 10.6684 15.797C10.6686 15.7969 10.6688 15.7968 10.669 15.7968L29.1688 6.99755ZM30.2178 10.5555L29.8959 10.4025L29.574 10.5555L13.2432 18.3155L12.935 18.4619L12.8429 18.7905C12.421 20.2959 12.052 21.8958 12.052 23.6098C12.052 43.0169 27.2931 49.601 29.624 50.5077L29.8959 50.6135L30.1678 50.5077C32.4988 49.601 47.7399 43.0169 47.7399 23.6098C47.7399 21.8958 47.3708 20.2959 46.9489 18.7905L46.8568 18.4619L46.5486 18.3155L30.2178 10.5555Z" fill="black" stroke="white" strokeWidth="1.5" />
            <path d="M38.2989 22.1797L38.3043 22.1724L38.3095 22.1649C38.8527 21.3851 39.8054 21.2412 40.6766 21.8245C41.4214 22.3794 41.58 23.4326 41.0292 24.1826L41.0289 24.183L30.2834 38.8346C29.6404 39.7105 28.3504 39.7621 27.6396 38.9404L27.6392 38.94L21.2914 31.6144C20.6802 30.908 20.7571 29.84 21.4632 29.2285L21.4636 29.2281C22.1691 28.6162 23.2371 28.6916 23.8496 29.3967L28.1941 34.4088L28.8097 35.1191L29.3656 34.3611L38.2989 22.1797Z" fill="black" stroke="white" strokeWidth="1.5" />
            <path d="M30.3574 0C24.424 0 18.6238 1.75947 13.6903 5.05591C8.75685 8.35235 4.91168 13.0377 2.64105 18.5195C0.370421 24.0013 -0.22368 30.0333 0.933877 35.8527C2.09143 41.6721 4.94866 47.0176 9.14423 51.2132C13.3398 55.4088 18.6853 58.266 24.5047 59.4236C30.3242 60.5811 36.3562 59.987 41.8379 57.7164C47.3197 55.4458 52.0051 51.6006 55.3015 46.6671C58.598 41.7336 60.3574 35.9334 60.3574 30C60.3484 22.0463 57.1848 14.4209 51.5607 8.79674C45.9366 3.17259 38.3112 0.00899894 30.3574 0ZM30.3574 58C24.8196 58 19.406 56.3578 14.8015 53.2812C10.1969 50.2045 6.60806 45.8315 4.48881 40.7151C2.36955 35.5988 1.81506 29.9689 2.89545 24.5375C3.97583 19.106 6.64257 14.1169 10.5584 10.201C14.4743 6.28514 19.4634 3.6184 24.8949 2.53801C30.3264 1.45763 35.9562 2.01212 41.0726 4.13137C46.1889 6.25063 50.5619 9.83946 53.6386 14.444C56.7153 19.0486 58.3574 24.4621 58.3574 30C58.349 37.4235 55.3963 44.5405 50.1471 49.7896C44.8979 55.0388 37.7809 57.9915 30.3574 58Z" fill="black" />
        </svg>

    )
}

export default VerifiedTrucksSvg